<template>
  <!-- Chart-->
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <h4 class="header-title mb-3">Flights</h4>
        </div>
        <div class="col d-flex justify-content-end pl-5">
          <multiselect
            v-model="selectedYear"
            :close-on-select="true"
            :multiple="false"
            :options="reverseYear"
            placeholder="Filter by year"
          />
        </div>
      </div>
      <div class="chartjs-chart">
        <barChart
          :height="260"
          :flightData="flightData"
          :selectedYear="selectedYear"
        />
      </div>
    </div>
  </div>
  <!-- End Chart-->
</template>
<script>
import barChart from "./barChart.vue";
import Multiselect from "vue-multiselect";
import { DateTime } from "luxon";
export default {
  components: { barChart, Multiselect },
  props: {
    flightData: {
      type: Array,
    },
  },
  data() {
    return {
      selectedYear: null,
      year: [],
    };
  },
  mounted() {
    this.selectedYear = DateTime.now().year;
    this.flightData.forEach((f) => {
      let y = DateTime.fromISO(f.flight_time).year;
      if (!this.year.includes(y)) {
        this.year.push(y);
      }
    });
  },
  computed: {
    reverseYear() {
      return this.year.slice().reverse();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
