<script>
import { Bar } from "vue-chartjs";
import { DateTime } from "luxon";
export default {
  props: {
    flightData: {
      type: Array,
    },
    selectedYear: {
      type: Number,
    },
  },
  extends: Bar,
  data() {
    return {
      datasets: [],
      total: [],
      flightMonth: {
        jan: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
      },
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      totalMonth: [],
      currentYear: null,
    };
  },
  watch: {
    selectedYear() {
      this.datasets = [];
      this.total = [];
      Object.keys(this.flightMonth).forEach((key) => {
        this.flightMonth[key] = 0;
      });
      this.yearFlights();
      this.loadChart();
    },
  },
  mounted() {
    this.yearFlights();
    this.loadChart();
  },
  methods: {
    yearFlights() {
      this.currentYear = DateTime.now().year;
      this.flightData.forEach((f) => {
        let month = DateTime.fromISO(f.flight_time).monthLong;
        let year = DateTime.fromISO(f.flight_time).year;
        if (year === this.currentYear && this.selectedYear === null) {
          this.updateChart(month);
        } else if (year === this.selectedYear) {
          this.updateChart(month);
        }
      });
    },
    loadChart() {
      this.total = Object.values(this.flightMonth);
      if (this.total.length > 0) {
        {
          this.datasets.push({
            barPercentage: 1,
            categoryPercentage: 0.7,
            backgroundColor: "#69afff",
            borderColor: "#f572d2",
            hoverBackgroundColor: "#f572d2",
            hoverBorderColor: "#727cf5",
            data: this.total,
          });
        }
      }
      this.renderChart(
        {
          labels: this.months,
          datasets: this.datasets,
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: true,
                  color: "rgba(0,0,0,0.02)",
                },
                stacked: true,
                ticks: {
                  stepSize: 10,
                },
              },
            ],
            xAxes: [
              {
                stacked: true,
                gridLines: {
                  color: "rgba(0,0,0,0.0)",
                },
              },
            ],
          },
        }
      );
    },
    updateChart(month) {
      switch (month) {
        case "January":
          this.flightMonth.jan += 1;
          break;
        case "February":
          this.flightMonth.feb += 1;
          break;
        case "March":
          this.flightMonth.mar += 1;
          break;
        case "April":
          this.flightMonth.apr += 1;
          break;
        case "May":
          this.flightMonth.may += 1;
          break;
        case "June":
          this.flightMonth.jun += 1;
          break;
        case "July":
          this.flightMonth.jul += 1;
          break;
        case "August":
          this.flightMonth.aug += 1;
          break;
        case "September":
          this.flightMonth.sep += 1;
          break;
        case "October":
          this.flightMonth.oct += 1;
          break;
        case "November":
          this.flightMonth.nov += 1;
          break;
        case "December":
          this.flightMonth.dec += 1;
          break;
      }
    },
  },
};
</script>
